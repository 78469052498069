/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/*=============================================
=            Index            =
=============================================

  -> Default
  -> Common
  -> Helper
  -> Spacing
  -> Header
  -> Footer
  -> Button
  -> Section Title
  -> Backgrounds
  -> Breadcrumb
  -> Preloader
  -> Hero Slider
  -> Category Slider
  -> Product
  -> Contact Content
  -> Profile
  -> Authentication
  -> Welcome
  -> Notification
  -> Chat
  -> Cart
  -> Checkout
  -> Banner
  -> Category

/*=====  End of Index  ======*/
/*=============================================
=            Default            =
=============================================*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
/* custom font */
@font-face {
  font-family: "Merriweather";
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("assets/fonts/Merriweather-Black.eot");
  src: url("assets/fonts/Merriweather-Black.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/Merriweather-Black.woff2") format("woff2"),
    url("assets/fonts/Merriweather-Black.woff") format("woff"),
    url("assets/fonts/Merriweather-Black.ttf") format("truetype"),
    url("assets/fonts/Merriweather-Black.svg#Merriweather-Black") format("svg");
}
@font-face {
  font-family: "Consolas";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("assets/fonts/Consolas.eot");
  src: url("assets/fonts/Consolas.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Consolas.woff2") format("woff2"),
    url("assets/fonts/Consolas.woff") format("woff"),
    url("assets/fonts/Consolas.ttf") format("truetype"),
    url("assets/fonts/Consolas.svg#Consolas") format("svg");
}
*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
  color: #4e4e4e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0;
  color: #000000;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  line-height: inherit;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-decoration: none;
  color: inherit;
}
a:focus,
button:focus {
  outline: none;
}

*:focus {
  outline: none;
}

a:focus {
  text-decoration: none;
  color: inherit;
  outline: none;
}

a:hover {
  text-decoration: none;
  color: #fd614b;
}

input[type="submit"] {
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
  list-style: outside none none;
}

select {
  padding-right: 15px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='15px'%3E%3Ctext x='0' y='10' fill='black'%3E%E2%96%BE%3C/text%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 107% 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  color: transparent;
}

/* FF 4-18 */
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent;
}

/* FF 19+ */
input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

/* IE 10+ */
/*=====  End of Default  ======*/
/*=============================================
=            Common            =
=============================================*/
.bg-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.border-bottom {
  border-bottom: 1px solid #f1f1f1 !important;
}
.border-bottom--medium {
  border-bottom: 2px solid #f1f1f1 !important;
}
.border-bottom--thick {
  border-bottom: 3px solid #f1f1f1 !important;
}

.body-wrapper.active-overlay {
  overflow: hidden;
}
.body-wrapper.active-overlay:before {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: transparent;
}

/*=====  End of Common  ======*/
/*=============================================
=            Helper            =
=============================================*/
.row-0 {
  margin-right: 0px;
  margin-left: 0px;
}
.row-0 > [class*="col"] {
  padding-right: 0px;
  padding-left: 0px;
}

.row-1 {
  margin-right: -1px;
  margin-left: -1px;
}
.row-1 > [class*="col"] {
  padding-right: 1px;
  padding-left: 1px;
}

.row-2 {
  margin-right: -2px;
  margin-left: -2px;
}
.row-2 > [class*="col"] {
  padding-right: 2px;
  padding-left: 2px;
}

.row-3 {
  margin-right: -3px;
  margin-left: -3px;
}
.row-3 > [class*="col"] {
  padding-right: 3px;
  padding-left: 3px;
}

.row-4 {
  margin-right: -4px;
  margin-left: -4px;
}
.row-4 > [class*="col"] {
  padding-right: 4px;
  padding-left: 4px;
}

.row-5 {
  margin-right: -5px;
  margin-left: -5px;
}
.row-5 > [class*="col"] {
  padding-right: 5px;
  padding-left: 5px;
}

.row-6 {
  margin-right: -6px;
  margin-left: -6px;
}
.row-6 > [class*="col"] {
  padding-right: 6px;
  padding-left: 6px;
}

.row-7 {
  margin-right: -7px;
  margin-left: -7px;
}
.row-7 > [class*="col"] {
  padding-right: 7px;
  padding-left: 7px;
}

.row-8 {
  margin-right: -8px;
  margin-left: -8px;
}
.row-8 > [class*="col"] {
  padding-right: 8px;
  padding-left: 8px;
}

.row-9 {
  margin-right: -9px;
  margin-left: -9px;
}
.row-9 > [class*="col"] {
  padding-right: 9px;
  padding-left: 9px;
}

.row-10 {
  margin-right: -10px;
  margin-left: -10px;
}
.row-10 > [class*="col"] {
  padding-right: 10px;
  padding-left: 10px;
}

.row-11 {
  margin-right: -11px;
  margin-left: -11px;
}
.row-11 > [class*="col"] {
  padding-right: 11px;
  padding-left: 11px;
}

.row-12 {
  margin-right: -12px;
  margin-left: -12px;
}
.row-12 > [class*="col"] {
  padding-right: 12px;
  padding-left: 12px;
}

.row-13 {
  margin-right: -13px;
  margin-left: -13px;
}
.row-13 > [class*="col"] {
  padding-right: 13px;
  padding-left: 13px;
}

.row-14 {
  margin-right: -14px;
  margin-left: -14px;
}
.row-14 > [class*="col"] {
  padding-right: 14px;
  padding-left: 14px;
}

.row-15 {
  margin-right: -15px;
  margin-left: -15px;
}
.row-15 > [class*="col"] {
  padding-right: 15px;
  padding-left: 15px;
}

.row-16 {
  margin-right: -16px;
  margin-left: -16px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-16 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-16 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-16 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-16 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-16 > [class*="col"] {
  padding-right: 16px;
  padding-left: 16px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-16 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-16 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-16 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-16 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-17 {
  margin-right: -17px;
  margin-left: -17px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-17 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-17 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-17 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-17 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-17 > [class*="col"] {
  padding-right: 17px;
  padding-left: 17px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-17 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-17 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-17 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-17 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-18 {
  margin-right: -18px;
  margin-left: -18px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-18 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-18 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-18 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-18 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-18 > [class*="col"] {
  padding-right: 18px;
  padding-left: 18px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-18 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-18 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-18 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-18 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-19 {
  margin-right: -19px;
  margin-left: -19px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-19 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-19 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-19 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-19 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-19 > [class*="col"] {
  padding-right: 19px;
  padding-left: 19px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-19 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-19 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-19 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-19 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-20 {
  margin-right: -20px;
  margin-left: -20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-20 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-20 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-20 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-20 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-20 > [class*="col"] {
  padding-right: 20px;
  padding-left: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-20 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-20 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-20 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-20 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-21 {
  margin-right: -21px;
  margin-left: -21px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-21 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-21 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-21 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-21 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-21 > [class*="col"] {
  padding-right: 21px;
  padding-left: 21px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-21 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-21 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-21 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-21 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-22 {
  margin-right: -22px;
  margin-left: -22px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-22 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-22 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-22 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-22 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-22 > [class*="col"] {
  padding-right: 22px;
  padding-left: 22px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-22 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-22 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-22 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-22 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-23 {
  margin-right: -23px;
  margin-left: -23px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-23 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-23 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-23 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-23 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-23 > [class*="col"] {
  padding-right: 23px;
  padding-left: 23px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-23 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-23 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-23 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-23 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-24 {
  margin-right: -24px;
  margin-left: -24px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-24 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-24 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-24 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-24 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-24 > [class*="col"] {
  padding-right: 24px;
  padding-left: 24px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-24 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-24 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-24 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-24 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-25 {
  margin-right: -25px;
  margin-left: -25px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-25 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-25 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-25 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-25 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-25 > [class*="col"] {
  padding-right: 25px;
  padding-left: 25px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-25 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-25 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-25 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-25 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-26 {
  margin-right: -26px;
  margin-left: -26px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-26 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-26 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-26 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-26 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-26 > [class*="col"] {
  padding-right: 26px;
  padding-left: 26px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-26 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-26 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-26 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-26 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-27 {
  margin-right: -27px;
  margin-left: -27px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-27 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-27 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-27 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-27 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-27 > [class*="col"] {
  padding-right: 27px;
  padding-left: 27px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-27 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-27 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-27 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-27 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-28 {
  margin-right: -28px;
  margin-left: -28px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-28 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-28 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-28 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-28 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-28 > [class*="col"] {
  padding-right: 28px;
  padding-left: 28px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-28 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-28 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-28 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-28 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-29 {
  margin-right: -29px;
  margin-left: -29px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-29 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-29 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-29 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-29 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-29 > [class*="col"] {
  padding-right: 29px;
  padding-left: 29px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-29 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-29 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-29 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-29 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-30 {
  margin-right: -30px;
  margin-left: -30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-30 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-30 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-30 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-30 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-30 > [class*="col"] {
  padding-right: 30px;
  padding-left: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-30 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-30 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-30 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-30 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-31 {
  margin-right: -31px;
  margin-left: -31px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-31 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-31 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-31 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-31 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-31 > [class*="col"] {
  padding-right: 31px;
  padding-left: 31px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-31 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-31 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-31 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-31 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-32 {
  margin-right: -32px;
  margin-left: -32px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-32 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-32 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-32 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-32 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-32 > [class*="col"] {
  padding-right: 32px;
  padding-left: 32px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-32 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-32 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-32 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-32 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-33 {
  margin-right: -33px;
  margin-left: -33px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-33 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-33 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-33 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-33 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-33 > [class*="col"] {
  padding-right: 33px;
  padding-left: 33px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-33 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-33 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-33 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-33 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-34 {
  margin-right: -34px;
  margin-left: -34px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-34 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-34 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-34 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-34 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-34 > [class*="col"] {
  padding-right: 34px;
  padding-left: 34px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-34 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-34 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-34 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-34 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-35 {
  margin-right: -35px;
  margin-left: -35px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-35 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-35 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-35 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-35 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-35 > [class*="col"] {
  padding-right: 35px;
  padding-left: 35px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-35 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-35 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-35 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-35 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-36 {
  margin-right: -36px;
  margin-left: -36px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-36 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-36 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-36 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-36 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-36 > [class*="col"] {
  padding-right: 36px;
  padding-left: 36px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-36 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-36 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-36 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-36 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-37 {
  margin-right: -37px;
  margin-left: -37px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-37 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-37 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-37 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-37 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-37 > [class*="col"] {
  padding-right: 37px;
  padding-left: 37px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-37 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-37 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-37 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-37 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-38 {
  margin-right: -38px;
  margin-left: -38px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-38 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-38 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-38 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-38 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-38 > [class*="col"] {
  padding-right: 38px;
  padding-left: 38px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-38 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-38 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-38 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-38 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-39 {
  margin-right: -39px;
  margin-left: -39px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-39 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-39 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-39 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-39 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-39 > [class*="col"] {
  padding-right: 39px;
  padding-left: 39px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-39 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-39 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-39 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-39 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-40 {
  margin-right: -40px;
  margin-left: -40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-40 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-40 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-40 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-40 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-40 > [class*="col"] {
  padding-right: 40px;
  padding-left: 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-40 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-40 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-40 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-40 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-41 {
  margin-right: -41px;
  margin-left: -41px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-41 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-41 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-41 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-41 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-41 > [class*="col"] {
  padding-right: 41px;
  padding-left: 41px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-41 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-41 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-41 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-41 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-42 {
  margin-right: -42px;
  margin-left: -42px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-42 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-42 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-42 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-42 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-42 > [class*="col"] {
  padding-right: 42px;
  padding-left: 42px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-42 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-42 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-42 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-42 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-43 {
  margin-right: -43px;
  margin-left: -43px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-43 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-43 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-43 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-43 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-43 > [class*="col"] {
  padding-right: 43px;
  padding-left: 43px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-43 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-43 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-43 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-43 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-44 {
  margin-right: -44px;
  margin-left: -44px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-44 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-44 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-44 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-44 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-44 > [class*="col"] {
  padding-right: 44px;
  padding-left: 44px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-44 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-44 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-44 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-44 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-45 {
  margin-right: -45px;
  margin-left: -45px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-45 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-45 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-45 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-45 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-45 > [class*="col"] {
  padding-right: 45px;
  padding-left: 45px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-45 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-45 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-45 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-45 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-46 {
  margin-right: -46px;
  margin-left: -46px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-46 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-46 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-46 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-46 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-46 > [class*="col"] {
  padding-right: 46px;
  padding-left: 46px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-46 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-46 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-46 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-46 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-47 {
  margin-right: -47px;
  margin-left: -47px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-47 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-47 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-47 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-47 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-47 > [class*="col"] {
  padding-right: 47px;
  padding-left: 47px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-47 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-47 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-47 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-47 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-48 {
  margin-right: -48px;
  margin-left: -48px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-48 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-48 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-48 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-48 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-48 > [class*="col"] {
  padding-right: 48px;
  padding-left: 48px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-48 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-48 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-48 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-48 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-49 {
  margin-right: -49px;
  margin-left: -49px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-49 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-49 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-49 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-49 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-49 > [class*="col"] {
  padding-right: 49px;
  padding-left: 49px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-49 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-49 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-49 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-49 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-50 {
  margin-right: -50px;
  margin-left: -50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-50 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-50 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-50 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-50 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.row-50 > [class*="col"] {
  padding-right: 50px;
  padding-left: 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-50 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-50 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-50 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-50 > [class*="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  margin: 0;
  padding-right: 0;
  padding-left: 0;
}

/*=====  End of Helper  ======*/
/*=============================================
=            Spacing            =
=============================================*/
.space-xy--10 {
  padding: 10px;
}
.space-xy--15 {
  padding: 15px;
}

.space-y--10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.space-y--15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.space-y--60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.space-mt--10 {
  margin-top: 10px;
}
.space-mt--20 {
  margin-top: 20px;
}
.space-mt--30 {
  margin-top: 30px;
}
.space-mt--40 {
  margin-top: 40px;
}
.space-mt--70 {
  margin-top: 70px;
}

.space-mb--5 {
  margin-bottom: 5px;
}
.space-mb--10 {
  margin-bottom: 10px;
}
.space-mb--15 {
  margin-bottom: 15px;
}
.space-mb--20 {
  margin-bottom: 20px;
}
.space-mb--25 {
  margin-bottom: 25px;
}
.space-mb--30 {
  margin-bottom: 30px;
}
.space-mb--40 {
  margin-bottom: 40px;
}
.space-mb--50 {
  margin-bottom: 50px;
}
.space-mb--70 {
  margin-bottom: 70px;
}
.space-mb--80 {
  margin-bottom: 80px;
}

.space-mb-m--15 {
  margin-bottom: -15px;
}
.space-mb-m--20 {
  margin-bottom: -20px;
}

.space-mr--20 {
  margin-right: 20px;
}

.space-pb--15 {
  padding-bottom: 15px;
}
.space-pb--25 {
  padding-bottom: 25px;
}
.space-pb--30 {
  padding-bottom: 30px;
}
.space-pb--50 {
  padding-bottom: 50px;
}
.space-pb--60 {
  padding-bottom: 60px;
}
.space-pb--90 {
  padding-bottom: 90px;
}
.space-pb--120 {
  padding-bottom: 120px;
}

.space-pt--5 {
  padding-top: 5px;
}
.space-pt--10 {
  padding-top: 10px;
}
.space-pt--15 {
  padding-top: 15px;
}
.space-pt--25 {
  padding-top: 25px;
}
.space-pt--30 {
  padding-top: 30px;
}
.space-pt--60 {
  padding-top: 60px;
}
.space-pt--70 {
  padding-top: 70px;
}
.space-pt--100 {
  padding-top: 100px;
}
.space-pt--120 {
  padding-top: 120px;
}

/*=====  End of Spacing  ======*/
/*=============================================
=            Header            =
=============================================*/
header.stick {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  /* background-color: #ffffff; */
}
header.stick .header-search {
  display: none;
}
header .header-search {
  width: 100%;
}
header .header-search form {
  position: relative;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
header .header-search form input {
  font-size: 14px;
  width: 100%;
  padding: 12px 30px 12px 50px;
  color: #666;
  border: none;
  border-radius: 5px;
  background-color: #ffffff;
}
header .header-search form input ::-webkit-input-placeholder {
  opacity: 1;
  /* Firefox */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b7b7b7;
}
header .header-search form input ::-moz-placeholder {
  opacity: 1;
  /* Firefox */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b7b7b7;
}
header .header-search form input :-ms-input-placeholder {
  opacity: 1;
  /* Firefox */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b7b7b7;
}
header .header-search form input ::-ms-input-placeholder {
  opacity: 1;
  /* Firefox */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b7b7b7;
}
header .header-search form input ::placeholder {
  opacity: 1;
  /* Firefox */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b7b7b7;
}
header .header-search form input :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b7b7b7;
}
header .header-search form input ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b7b7b7;
}
header .header-search form img {
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
header .header-menu-trigger {
  padding: 0;
  border: none;
  background: none;
}
header .header-menu-trigger:focus {
  outline: none;
}

/* offcanvas menu */
.profile-card__image img {
  border-radius: 50%;
}
.profile-card__content p {
  font-weight: 500;
  color: #06142d;
}
.profile-card__content p span {
  font-size: 12px;
  font-weight: 400;
  display: block;
}

.offcanvas-menu {
  position: fixed;
  z-index: 9999;
  top: 60px;
  left: 0;
  visibility: hidden;
  width: 280px;
  max-width: 100%;
  height: 100vh;
  margin-left: auto;
  padding: 40px;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  background-color: #f1f1f1;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f1f1f1),
    to(#f2f8ff)
  );
  background-image: linear-gradient(to bottom, #f1f1f1 0%, #f2f8ff 100%);
  -webkit-box-shadow: -5px 0 15px rgba(0, 0, 0, 0.16);
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.16);
}
.offcanvas-menu.active {
  visibility: visible;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.offcanvas-navigation {
  overflow-y: scroll;
  height: 400px;
  margin: 0 -40px;
  padding: 0 40px;
}
.offcanvas-navigation li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 10px 15px 30px;
  border-bottom: 2px solid #ffffff;
}
.offcanvas-navigation li:nth-child(1) {
  border-top: 2px solid #ffffff;
}
.offcanvas-navigation li span {
  display: block;
  margin-right: 15px;
}
.offcanvas-navigation li span svg {
  width: 20px;
  height: 14px;
}
.offcanvas-navigation li a {
  display: block;
  width: 100%;
  color: #06142d;
}

/* search keyword */
.search-keyword-area {
  display: none;
}

.search-keyword-header {
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.search-keyword-header__title {
  font-size: 14px;
  -ms-flex-preferred-size: 80%;
  flex-basis: 80%;
}
.search-keyword-header__gl-switcher {
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
}
.search-keyword-header__gl-switcher button {
  padding: 0;
  border: none;
  background: none;
}

.search-keywords {
  margin-bottom: -10px;
}
.search-keywords li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
.search-keywords li a {
  font-size: 13px;
  line-height: 1.3;
  display: block;
  padding: 5px 15px;
  color: #111111;
  border: 1px solid #e1e1e1;
  background-color: #ffffff;
}

@-webkit-keyframes fadeInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes fadeInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
/*=====  End of Header  ======*/
/*=============================================
=            Footer            =
=============================================*/
.footer-nav-wrapper {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 0 40px;
  border-radius: 60px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.49);
}
.footer-nav-wrapper--styleTwo {
  border-radius: 0;
}

.footer-nav-single {
  position: relative;
  display: block;
  width: 85px;
  height: 85px;
  text-align: center;
  border-radius: 50%;
}
@media only screen and (max-width: 400px) {
  .footer-nav-single {
    width: 60px;
    height: 60px;
  }
}
.footer-nav-single span {
  font-size: 12px;
  color: #6e6e6e;
}
.footer-nav-single svg {
  display: block;
  margin: 0 auto;
  margin-bottom: 5px;
}
@media only screen and (max-width: 400px) {
  .footer-nav-single svg {
    width: auto;
    height: 22px;
  }
}
.footer-nav-single .menu-wrapper {
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.footer-nav-single:before {
  position: absolute;
  z-index: -1;
  top: -5px;
  left: -5px;
  visibility: hidden;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  content: "";
  opacity: 0;
  border-radius: 50%;
  background-color: #ffffff;
}
.footer-nav-single:hover,
.footer-nav-single.active {
  background-color: #4a44f2;
}
.footer-nav-single:hover span,
.footer-nav-single.active span {
  color: #ffffff;
}
.footer-nav-single:hover svg *,
.footer-nav-single.active svg * {
  stroke: #ffffff;
}
.footer-nav-single:hover:before,
.footer-nav-single.active:before {
  visibility: visible;
  opacity: 1;
}
.footer-nav-single--styleTwo svg * {
  stroke: #e8e1d6;
}
.footer-nav-single--styleTwo:before {
  position: absolute;
  z-index: -1;
  top: -10px;
  left: -5px;
  visibility: hidden;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  content: "";
  opacity: 0;
  border: 6px solid #e8e1d6;
  border-radius: 50%;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
}
.footer-nav-single--styleTwo:hover,
.footer-nav-single--styleTwo.active {
  background-color: transparent;
}
.footer-nav-single--styleTwo:hover svg *,
.footer-nav-single--styleTwo.active svg * {
  stroke: #878787;
}
.footer-nav-single--styleTwo:hover:before,
.footer-nav-single--styleTwo.active:before {
  visibility: visible;
  opacity: 1;
}

/*=====  End of Footer ======*/
/*=============================================
=            Button            =
=============================================*/
/*=====  End of Button  ======*/
/*=============================================
=            Section Title            =
=============================================*/
.section-title {
  font-size: 18px;
  position: relative;
  color: #505050;
}
.section-title a {
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #aaaaaa;
}

/*=====  End of Section Title  ======*/
.bg-color--grey {
  background-color: rgba(179, 181, 183, 0.1);
}

.bg-color--white {
  background-color: rgba(255, 255, 255, 1);
}

.bg-color--grey2 {
  background-color: #f8f8f8;
}

.bg-color--grey3 {
  background-color: #f7f8f9;
}

.bg-color--transparent {
  background-color: transparent;
}

.bg-color--gradient {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ffffff),
    to(#ffffff)
  );
  background-image: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
}

/*=============================================
=            breadcrumb            =
=============================================*/
.breadcrumb-area .page-title {
  font-size: 16px;
  font-weight: 500;
  color: #555;
}

/*=====  End of breadcrumb  ======*/
/*=============================================
=            preloader            =
=============================================*/
.preloader-active {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}
.preloader-active .preloader-area-wrap {
  position: absolute;
  z-index: 999999;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.preloader-active .preloader-area-wrap .img-loader {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  display: block;
  width: 30px;
  height: 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-animation: rotate-center 0.6s linear infinite both;
  animation: rotate-center 0.6s linear infinite both;
  border-width: 3px;
  border-style: solid;
  border-color: #fd614b #fd614b #fd614b transparent;
  border-radius: 50%;
}

.preloader-area-wrap {
  display: none;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*=====  End of preloader  ======*/
/*=============================================
=            hero slider            =
=============================================*/
.hero-slider-wrapper .slick-active .hero-slider-content > *:nth-child(1) {
  -webkit-animation-name: fadeInTop;
  animation-name: fadeInTop;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.hero-slider-wrapper .slick-active .hero-slider-content > *:nth-child(2) {
  -webkit-animation-name: fadeInTop;
  animation-name: fadeInTop;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
.hero-slider-wrapper .slick-dots {
  line-height: 1;
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.hero-slider-wrapper .slick-dots li {
  display: inline-block;
  margin-right: 5px;
}
.hero-slider-wrapper .slick-dots li button {
  font-size: 0;
  width: 15px;
  height: 15px;
  padding: 0;
  border: 3px solid #d8d8d8;
  border-radius: 50%;
  background: none;
  background-color: #ffffff;
}
.hero-slider-wrapper .slick-dots li.slick-active button {
  background-color: #fd614b;
}
.hero-slider-wrapper .slick-dots li:hover button,
.hero-slider-wrapper .slick-dots li:focus button {
  background-color: #fd614b;
}

.hero-slider-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: auto !important;
  height: 170px;
  border-radius: 5px;
}

.hero-slider-content {
  width: 300px;
  max-width: 100%;
}
.hero-slider-content > * {
  -webkit-animation-name: fadeOutTop;
  animation-name: fadeOutTop;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.hero-slider-content__title {
  font-family: "Merriweather";
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 5px;
  color: #302119;
}
.hero-slider-content__text {
  font-family: "Consolas";
  font-size: 14px;
  color: #302119;
}

/*=====  End of hero slider  ======*/
/*=============================================
=            category slider            =
=============================================*/
.category-item {
  text-align: center;
}
.category-item__image {
  /* position: relative;
  width: 60px;
  height: 60px;
  margin: 0 auto; */
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  flex-direction: row;
  align-items: center;
  align-content: center;
}
.category-item__image a {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.category-item__title a {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  display: block;
  color: #9c9a9a;
}
.category-item__title a:hover {
  text-decoration: underline;
  color: #fd614b;
}

/*=====  End of category slider  ======*/
/*=============================================
=            product             =
=============================================*/
/* featured product */
.featured-product {
  padding: 10px;
  border-radius: 10px;
  background-color: #f8f9f9;
}
.featured-product__image {
  margin-bottom: 20px;
  text-align: center;
}
.featured-product__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.featured-product__content .price {
  font-size: 11px;
  line-height: 1;
  padding: 5px;
  color: #ffffff;
  border-radius: 10px;
  background-color: #ee3625;
}
.featured-product__content .price .main-price {
  text-decoration: line-through;
}
.featured-product__content .icon {
  line-height: 1;
}
.featured-product__content .icon button {
  padding: 0;
  border: none;
  background: none;
}
.featured-product__content .icon button svg * {
  fill: transparent;
  stroke: #4a44f2;
}
.featured-product__content .icon button:hover svg *,
.featured-product__content .icon button.active svg * {
  fill: #4a44f2;
}

/* grid product */
.grid-product__image {
  position: relative;
  margin-bottom: 10px;
  text-align: center;
  background-color: #ffffff;
}
.grid-product__image a {
  display: block;
}
.grid-product__image a img {
  width: 100%;
}

.grid-product__image img.quicklink-img {
  max-width: 50% !important;
}

.grid-product__image .icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  background: none;
}
.grid-product__image .icon:hover svg *,
.grid-product__image .icon.active svg * {
  fill: #fd614b;
}
.grid-product__content .title {
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 5px;
  color: #555;
}
.grid-product__content .price {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #fd614b;
}
.grid-product__content .price .main-price {
  font-size: 11px;
  font-weight: 500;
  text-decoration: line-through;
  color: #b4b4b4;
}

/* list product */
.list-product {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 15px;
}
.list-product__image {
  -ms-flex-preferred-size: 145px;
  flex-basis: 145px;
  margin-right: 20px;
  background-color: #f8f9f9;
}
.list-product__content {
  -ms-flex-preferred-size: calc(100% - 165px);
  flex-basis: calc(100% - 165px);
}
.list-product__content .title {
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 5px;
}
.list-product__content .category {
  font-size: 12px;
  line-height: 1;
  display: inline-block;
  margin-bottom: 10px;
  color: #6e6e6e;
}
.list-product__content .price {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 5px;
  color: #fd614b;
}
.list-product__content .price .main-price {
  font-size: 11px;
  font-weight: 500;
  text-decoration: line-through;
  color: #b4b4b4;
}
.list-product .icon {
  position: absolute;
  top: 30px;
  right: 15px;
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  background: none;
}
.list-product .icon:hover svg *,
.list-product .icon.active svg * {
  fill: #fd614b;
}

/* cart product */
.cart-product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px;
}
.cart-product__image {
  -ms-flex-preferred-size: 90px;
  flex-basis: 90px;
  margin-right: 10px;
  background-color: #f8f9f9;
}
.cart-product__content {
  -ms-flex-preferred-size: calc(100% - 190px);
  flex-basis: calc(100% - 190px);
}
.cart-product__content .title {
  font-size: 16px;
  line-height: 1.3;
}
.cart-product__content .category {
  font-size: 12px;
  line-height: 1;
  display: inline-block;
  margin-bottom: 7px;
  color: #6e6e6e;
}
.cart-product__content .price {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 10px;
  color: #fd614b;
}
.cart-product__content .price .main-price {
  font-size: 11px;
  font-weight: 500;
  text-decoration: line-through;
  color: #b4b4b4;
}
.cart-product__status p span {
  color: #fd614b;
}

/* shop product image slider */
.product-image-slider-wrapper {
  position: relative;
}
.product-image-slider-wrapper .product-image-single img {
  margin: 0 auto;
}
.product-image-slider-wrapper .slick-list {
  padding-top: 60px;
  padding-bottom: 60px;
  border-bottom-right-radius: 100px;
  background-color: #f8f9f9;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
}
.product-image-slider-wrapper .slick-slide > div > div {
  vertical-align: middle;
}
.product-image-slider-wrapper .slick-dots {
  line-height: 1;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.product-image-slider-wrapper .slick-dots li {
  display: inline-block;
  margin-right: 15px;
}
.product-image-slider-wrapper .slick-dots li:last-child {
  margin-right: 0;
}
.product-image-slider-wrapper .slick-dots li button {
  font-size: 0;
  width: 15px;
  height: 15px;
  padding: 0;
  border: 1px solid rgba(48, 48, 48, 0.3);
  border-radius: 50%;
  background: none;
}
.product-image-slider-wrapper .slick-dots li.slick-active button {
  border-color: #fd614b;
  background-color: #fd614b;
}

/* shop product header */
.product-content-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.product-content-header__main-info .title {
  font-size: 18px;
  margin-bottom: 5px;
}
.product-content-header__main-info .price {
  font-size: 22px;
  font-weight: 500;
  color: #fd614b;
}
.product-content-header__main-info .price .main-price {
  text-decoration: line-through;
}
.product-content-header .rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.product-content-header .rating__stars {
  margin-right: 10px;
}
.product-content-header .rating__stars li {
  display: inline-block;
  margin-right: 3px;
}
.product-content-header .rating__text {
  padding: 0 12px;
  color: #ffffff;
  border-radius: 20px;
  background-color: #fd614b;
}
.product-content-header__wishlist-info svg {
  width: 24px;
  height: 21px;
  margin-bottom: 5px;
}
.product-content-header__wishlist-info .count {
  display: block;
}

/* shop product description */
.product-content-description h4,
.product-content-safety h4 {
  font-size: 14px;
}

/* shop product safety */
.product-content-safety svg {
  margin-right: 5px;
}

/* product-color-picker */
.product-color-picker h3 {
  font-size: 16px;
}
.product-color-picker form .color-picker {
  line-height: 1;
}
.product-color-picker form .color-picker li {
  display: inline-block;
  margin-right: 10px;
}
.product-color-picker form .color-picker li:last-child {
  margin-right: 0;
}
.product-color-picker form .color-picker li input[type="radio"] {
  width: 0;
  opacity: 0;
}
.product-color-picker
  form
  .color-picker
  li
  input[type="radio"]:checked
  + label {
  border: 4px solid #fd614b;
}
.product-color-picker form .color-picker li label {
  line-height: 1;
  width: 40px;
  height: 40px;
  margin-bottom: 0;
  border: 4px solid #e6e6e6;
  border-radius: 50%;
}
.product-color-picker form .color-picker li label.black {
  background-color: #404740;
}
.product-color-picker form .color-picker li label.blue {
  background-color: #722adf;
}
.product-color-picker form .color-picker li label.pink {
  background-color: #f93b7c;
}
.product-color-picker form .color-picker li label.brown {
  background-color: #945e38;
}

/* shop product button */
.shop-product-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.shop-product-button button {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  display: block;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  padding: 15px 0;
  border: none;
  background: none;
}
.shop-product-button button.cart {
  color: #000000;
  background-color: #eeeeff;
}
.shop-product-button button.buy {
  color: #ffffff;
  background-color: #d11402;
}

/* shop header */
.shop-header .category-title {
  font-size: 14px;
}
.shop-header .filter-trigger {
  font-weight: 500;
  padding: 0;
  color: #000000;
  border: none;
  background: none;
}
.shop-header .back-link {
  font-weight: 500;
  color: #000000;
}
.shop-header .back-link i {
  margin-right: 3px;
}

/* shop filter */
.shop-filter {
  display: none;
  background: #f8f8f8;
  -webkit-box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
}
.shop-filter-block__title {
  font-size: 14px;
}
.shop-filter-block__content ul li {
  display: inline-block;
}
.shop-filter-block__content ul li button {
  padding: 0;
}
.shop-filter-block__content .widget-price-range .irs--round {
  line-height: 1;
}
.shop-filter-block__content .widget-price-range .irs--round .irs .irs-line {
  height: 4px;
  border-radius: 0;
  background-color: #cbcbcb;
}
.shop-filter-block__content .widget-price-range .irs--round .irs .irs-from,
.shop-filter-block__content .widget-price-range .irs--round .irs .irs-to {
  font-size: 12px;
  top: 5px;
  padding: 5px;
  color: #929292;
  background-color: transparent;
}
.shop-filter-block__content
  .widget-price-range
  .irs--round
  .irs
  .irs-from:before,
.shop-filter-block__content
  .widget-price-range
  .irs--round
  .irs
  .irs-to:before {
  display: none;
}
.shop-filter-block__content .widget-price-range .irs--round .irs-bar {
  background: #000000;
}
.shop-filter-block__content .widget-price-range .irs--round .irs-handle {
  top: 30px;
  width: 15px;
  height: 15px;
  border: 4px solid #000000;
}
.shop-filter-block__size li {
  margin-right: 20px;
}
.shop-filter-block__size li button {
  width: 35px;
  height: 35px;
  color: #aaaaaa;
  border: 1px solid #ececec;
  border-radius: 50%;
  background-color: #ffffff;
}
.shop-filter-block__size li button:hover,
.shop-filter-block__size li button.active {
  color: #ffffff;
  background-color: #232323;
}
.shop-filter-block__size li:last-child {
  margin-right: 0;
}
.shop-filter-block__brand li {
  margin-right: 40px;
}
.shop-filter-block__brand li button {
  font-size: 12px;
  position: relative;
  color: #929292;
  border: none;
  background: none;
}
.shop-filter-block__brand li button:before {
  position: absolute;
  top: 0;
  left: -12px;
  visibility: hidden;
  content: url("assets/img/icons/check.svg");
  opacity: 0;
}
.shop-filter-block__brand li button:hover:before,
.shop-filter-block__brand li button.active:before {
  visibility: visible;
  opacity: 1;
}
.shop-filter-block__brand li:last-child {
  margin-right: 0;
}
.shop-filter-block .shop-filter-btn {
  font-size: 16px;
  font-weight: 500;
  width: 135px;
  padding: 5px 0;
  border: 1px solid #a8a8a8;
  border-radius: 20px;
}
.shop-filter-block .shop-filter-btn.apply-btn {
  margin-right: 30px;
  color: #ffffff;
  border-color: #fd614b;
  background-color: #fd614b;
}
.shop-filter-block .shop-filter-btn.cancel-btn {
  color: #c6c6c6;
  background-color: transparent;
}

.shop-dropdown select {
  font-size: 14px;
  font-weight: 500;
  color: #9c9a9a;
  border: none;
  background-color: transparent;
}

/*=====  End of product   ======*/
/* contact content */
.contact-page-content__image {
  height: 200px;
}
.contact-page-content__text h3 {
  font-size: 18px;
  margin-bottom: 10px;
}
.contact-page-content__text-block h4 {
  font-size: 14px;
  margin-bottom: 5px;
}

.map-wrapper iframe {
  width: 100%;
  height: 300px;
  border: none;
}

/*=============================================
=            profile            =
=============================================*/
/* profile header */
.profile-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.profile-header__image {
  -ms-flex-preferred-size: 120px;
  flex-basis: 120px;
  margin-right: 15px;
}
.profile-header__image img {
  border-radius: 15px;
}
.profile-header__content {
  -ms-flex-preferred-size: calc(100% - 135px);
  flex-basis: calc(100% - 135px);
}
.profile-header__content .name {
  font-size: 16px;
  font-weight: 700;
}
.profile-header .profile-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  max-width: 100%;
}
.profile-header .profile-info-block {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}
.profile-header .profile-info-block__value {
  font-size: 12px;
  font-weight: 700;
  color: #06142d;
}
.profile-header .profile-info-block__title {
  font-size: 10px;
  line-height: 1;
}
.profile-header .profile-level__title {
  font-size: 12px;
  font-weight: 500;
  color: #06142d;
}
.profile-header .profile-level__progress {
  height: 4px;
  background-color: rgba(145, 145, 161, 0.1);
}
.profile-header .profile-level__progress .progress-bar {
  background-color: #fd614b;
}

/* profile body */
.profile-info-table {
  border-radius: 10px;
  background-color: #ffffff;
}
.profile-info-table .profile-info-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 30px;
  color: #06142d;
  border-bottom: 2px solid #f1f1f1;
}
.profile-info-table .profile-info-block:last-child {
  border-bottom: none;
}
.profile-info-table .profile-info-block__title {
  font-size: 14px;
  font-weight: 500;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}
.profile-info-table .profile-info-block__value {
  font-size: 12px;
  font-weight: 300;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  text-align: right;
}
.profile-info-table .profile-info-block__value a svg {
  width: 15px;
  height: 15px;
}

.edit-profile-form {
  padding: 40px 20px;
  background-color: #ffffff;
}
.edit-profile-form__single-field label {
  font-size: 14px;
  margin-bottom: 20px;
  color: #02011e;
}
.edit-profile-form__single-field input,
.edit-profile-form__single-field textarea {
  font-size: 18px;
  display: block;
  width: 100%;
  padding-bottom: 10px;
  color: #02011e;
  border: none;
  border-bottom: 2px solid #e6e6e6;
}
.edit-profile-form__single-field input:focus,
.edit-profile-form__single-field textarea:focus {
  border-bottom: 2px solid #fd614b;
}
.edit-profile-form__single-field ::-webkit-input-placeholder {
  opacity: 1;
  /* Firefox */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6e6e;
}
.edit-profile-form__single-field ::-moz-placeholder {
  opacity: 1;
  /* Firefox */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6e6e;
}
.edit-profile-form__single-field :-ms-input-placeholder {
  opacity: 1;
  /* Firefox */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6e6e;
}
.edit-profile-form__single-field ::-ms-input-placeholder {
  opacity: 1;
  /* Firefox */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6e6e;
}
.edit-profile-form__single-field ::placeholder {
  opacity: 1;
  /* Firefox */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6e6e;
}
.edit-profile-form__single-field :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6e6e6e;
}
.edit-profile-form__single-field ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6e6e6e;
}
.edit-profile-form__button {
  font-size: 16px;
  line-height: 1;
  width: 100%;
  padding: 15px 0;
  color: #ffffff;
  border: none;
  background: none;
  background-color: #fd614b;
}

/*=====  End of profile  ======*/
/* auth page header */
.auth-page-header__title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #02011e;
}
.auth-page-header__text {
  color: #6e6e6e;
}

/* auth page body */
.auth-page-body .auth-form,
.auth-page-body-1 .auth-form {
  padding: 40px 20px;
  background-color: #ffffff;
}
.auth-page-body .auth-form__single-field label {
  font-size: 14px;
  margin-bottom: 20px;
  color: #02011e;
}
.auth-page-body .auth-form__single-field input,
.auth-page-body .auth-form__single-field textarea {
  font-size: 18px;
  display: block;
  width: 100%;
  padding-bottom: 10px;
  color: #02011e;
  border: none;
  border-bottom: 2px solid #e6e6e6;
}
.auth-page-body .auth-form__single-field input:focus,
.auth-page-body .auth-form__single-field textarea:focus {
  border-bottom: 2px solid #fd614b;
}
.auth-page-body .auth-form__single-field ::-webkit-input-placeholder {
  opacity: 1;
  /* Firefox */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6e6e;
}
.auth-page-body .auth-form__single-field ::-moz-placeholder {
  opacity: 1;
  /* Firefox */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6e6e;
}
.auth-page-body .auth-form__single-field :-ms-input-placeholder {
  opacity: 1;
  /* Firefox */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6e6e;
}
.auth-page-body .auth-form__single-field ::-ms-input-placeholder {
  opacity: 1;
  /* Firefox */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6e6e;
}
.auth-page-body .auth-form__single-field ::placeholder {
  opacity: 1;
  /* Firefox */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6e6e;
}
.auth-page-body .auth-form__single-field :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6e6e6e;
}
.auth-page-body .auth-form__single-field ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6e6e6e;
}
.auth-page-body .auth-form__button,
.checkout-button {
  font-size: 16px;
  line-height: 1;
  width: 100%;
  padding: 15px 0;
  color: #ffffff;
  border: none;
  background: none;
  background-color: #fd614b;
}
.auth-page-body .auth-form__info-text {
  color: #6e6e6e;
}
.auth-page-body .auth-form__info-text a {
  color: #fd614b;
}

/* auth page footer */
.auth-page-separator {
  font-size: 18px;
  line-height: 1;
  display: block;
  color: #02011e;
}

.auth-page-social-login button {
  line-height: 1;
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 15px 0;
  color: #000000;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background-color: transparent;
}
.auth-page-social-login button:last-child {
  margin-bottom: 0;
}
.auth-page-social-login button svg {
  position: absolute;
  top: 50%;
  left: 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*=============================================
=            welcome area            =
=============================================*/
.welcome-slider-wrapper {
  position: relative;
}
.welcome-slider-wrapper .logo img {
  margin: 0 auto;
}
.welcome-slider-wrapper .title {
  font-size: 14px;
  font-weight: 500;
}
.welcome-slider-wrapper .text {
  font-size: 16px;
  color: #404040;
}
.welcome-slider-wrapper .welcome-btn {
  font-size: 16px;
  line-height: 1;
  display: block;
  width: 140px;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 10px 0;
  color: #ffffff;
  border-radius: 20px;
  background-color: #fd614b;
}
.welcome-slider-wrapper .skip-btn {
  color: #6b6b6b;
  border: none;
  background: none;
}
.welcome-slider-wrapper .slick-dots {
  line-height: 1;
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.welcome-slider-wrapper .slick-dots li {
  display: inline-block;
  margin-right: 10px;
}
.welcome-slider-wrapper .slick-dots li:last-child {
  margin-right: 0;
}
.welcome-slider-wrapper .slick-dots li.slick-active button {
  background-color: #fd614b;
}
.welcome-slider-wrapper .slick-dots li button {
  font-size: 0;
  width: 8px;
  height: 8px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background: none;
  background-color: #6e6e6e;
}

.single-welcome-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  height: 100vh;
  padding: 30px 0;
}

/*=====  End of welcome area  ======*/
/*=============================================
=            notification            =
=============================================*/
.notification-item {
  padding: 20px 15px;
  border-bottom: 1px solid #d8d8d8;
}
.notification-item__content {
  margin-bottom: 10px;
}
.notification-item__content .highlight {
  font-weight: 700;
  color: #000000;
}
.notification-item__time {
  font-size: 12px;
  line-height: 1;
  position: relative;
  padding-left: 15px;
  vertical-align: middle;
}
.notification-item__time span {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.notification-item--unread {
  background-color: #eeeeff;
}

/*=====  End of notification  ======*/
/*=============================================
=            chat            =
=============================================*/
.chat-wrap {
  padding: 0 15px 40px 15px;
}

/*Chat List*/
.chat-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.chat-list li .chat {
  margin-bottom: 25px;
}
.chat-list li:nth-child(2n) .chat .head {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.chat-list li:nth-child(2n) .chat .head h5 {
  text-align: right;
}
.chat-list li:nth-child(2n) .chat .head span {
  margin-right: 0;
  margin-left: 15px;
}
.chat-list li:nth-child(2n) .chat .body {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.chat-list li:nth-child(2n) .chat .body .image {
  margin-right: 0;
  margin-left: 15px;
}
.chat-list li:nth-child(2n) .chat .body .content {
  margin-right: 15px;
  margin-left: 0;
}
.chat-list li:nth-child(2n) .chat .body .content::before {
  right: -10px;
  left: auto;
  border-width: 6px 0 6px 10px;
  border-color: transparent transparent transparent #ffffff;
}

/*Chat*/
.chat .head {
  position: relative;
  margin-bottom: 5px;
}
.chat .head h5 {
  font-size: 14px;
  line-height: 18px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0;
  text-align: left !important;
}
.chat .head span {
  font-size: 12px;
  line-height: 18px;
  margin-right: 20px;
  margin-bottom: 3px;
  margin-left: 0 !important;
}
.chat .head a {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 3px;
  color: #fd614b;
}
.chat .head a i {
  font-size: 18px;
  line-height: 18px;
  display: block;
}
.chat .body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.chat .body .image {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 40px;
  flex: 1 0 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 40px;
  margin-right: 15px;
}
.chat .body .image img {
  overflow: hidden;
  width: 100%;
  height: auto;
  border-radius: 50%;
}
.chat .body .content {
  position: relative;
  margin: 15px 0 0 !important;
  padding: 15px;
  border-radius: 4px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 5px rgba(24, 24, 24, 0.05);
  box-shadow: 0 0 5px rgba(24, 24, 24, 0.05);
}
.chat .body .content::before {
  position: absolute;
  top: 28px;
  left: -10px;
  display: none;
  content: "";
  border-width: 6px 10px 6px 0;
  border-style: solid;
  border-color: transparent #ffffff transparent transparent;
}
.chat .body .content p {
  font-size: 13px;
  line-height: 22px;
}

/*Chat Submission*/
.chat-submission {
  position: fixed;
  bottom: 85px;
  width: 100%;
  border-top: 1px solid #e1e1e1;
  background-color: #ffffff;
}
@media only screen and (max-width: 400px) {
  .chat-submission {
    bottom: 60px;
  }
}
.chat-submission form {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  padding: 10px 120px 10px 15px;
}
.chat-submission form input {
  line-height: 20px;
  width: 100%;
  padding: 15px 15px;
  border: none;
  background-color: transparent;
}
.chat-submission form .buttons {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.chat-submission form .buttons .submit,
.chat-submission form .buttons label {
  width: 40px;
  height: 40px;
  padding: 0;
  text-align: center;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  background: none;
  background-color: #fd614b;
}
.chat-submission form .buttons .submit i,
.chat-submission form .buttons label i {
  margin-right: 3px;
}
.chat-submission form .buttons .file-upload {
  line-height: 1;
  margin: 0;
  cursor: pointer;
}
.chat-submission form .buttons .file-upload input {
  display: none;
}
.chat-submission form .buttons .file-upload i {
  font-size: 18px;
  line-height: 36px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/*=====  End of chat  ======*/
/*=============================================
=            cart            =
=============================================*/
.cart-plus-minus {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 30px;
  padding: 0;
}
.cart-plus-minus .qtybutton {
  font-size: 16px;
  line-height: 30px;
  position: absolute;
  float: inherit;
  width: 20px;
  margin: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-align: center;
  color: #111111;
}
.cart-plus-minus .dec.qtybutton {
  top: 0;
  left: 0;
  height: 30px;
  border-right: 1px solid #e6e6e6;
}
.cart-plus-minus .inc.qtybutton {
  top: 0;
  right: 0;
  height: 30px;
  border-left: 1px solid #e6e6e6;
}
.cart-plus-minus input.cart-plus-minus-box {
  font-size: 14px;
  float: left;
  width: 90px;
  height: 30px;
  margin: 0;
  padding: 0;
  text-align: center;
  color: #111111;
  border: 1px solid #e6e6e6;
  background: transparent none repeat scroll 0 0;
}

.discount-code-wrapper {
  padding: 30px 0;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  border-radius: 5px;
}
.discount-code-wrapper .title-wrap {
  position: relative;
}
.discount-code-wrapper .title-wrap::before {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.discount-code-wrapper .title-wrap h4.cart-bottom-title {
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  margin: 0;
  padding-right: 18px;
}
.discount-code-wrapper .discount-code {
  margin: 21px 0 0;
}
.discount-code-wrapper .discount-code p {
  margin: 0 0 15px;
}
.discount-code-wrapper .discount-code form input {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  padding-left: 10px;
  border: 1px solid #f1f1f1;
}
.discount-code-wrapper .discount-code form button.cart-btn-2 {
  font-size: 14px;
  font-weight: 500;
  padding: 13px 30px 12px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-transform: uppercase;
  color: #ffffff;
  border: medium none;
  background-color: #fd614b;
}

.grand-total {
  padding: 30px 0;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}
.grand-total .title-wrap {
  position: relative;
}
.grand-total .title-wrap::before {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.grand-total .title-wrap h4.cart-bottom-title {
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  margin: 0;
  padding-right: 18px;
}
.grand-total .title-wrap .section-bg-gary-cart {
  position: relative;
  z-index: 9;
}
.grand-total h5 {
  font-size: 14px;
  margin: 36px 0 27px;
}
.grand-total h5 span {
  font-size: 18px;
  font-weight: 500;
  float: right;
}
.grand-total .total-shipping {
  margin: 0 0 27px;
  padding: 28px 0;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}
.grand-total .total-shipping h5 {
  font-size: 14px;
  margin: 0;
}
.grand-total .total-shipping ul {
  padding: 19px 0 0 0;
}
.grand-total .total-shipping ul li {
  margin: 0 0 6px;
  list-style: outside none none;
}
.grand-total .total-shipping ul li:last-child {
  margin: 0 0 0;
}
.grand-total .total-shipping ul li span {
  float: right;
}
.grand-total h4.grand-total-title {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 25px;
}
.grand-total h4.grand-total-title span {
  float: right;
}
.grand-total a {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  display: block;
  padding: 18px 10px 17px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #fd614b;
}

/*=====  End of cart  ======*/
/*=============================================
=            checkout            =
=============================================*/
.checkout-form {
  padding: 40px 20px;
  background-color: #ffffff;
}
.checkout-form__single-field label {
  font-size: 14px;
  margin-bottom: 20px;
  color: #02011e;
}
.checkout-form__single-field input,
.checkout-form__single-field textarea {
  font-size: 18px;
  display: block;
  width: 100%;
  padding-bottom: 10px;
  color: #02011e;
  border: none;
  border-bottom: 2px solid #e6e6e6;
}
.checkout-form__single-field input:focus,
.checkout-form__single-field textarea:focus {
  border-bottom: 2px solid #fd614b;
}
.checkout-form__single-field ::-webkit-input-placeholder {
  /* Firefox */
  opacity: 1;
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6e6e;
}
.checkout-form__single-field ::-moz-placeholder {
  /* Firefox */
  opacity: 1;
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6e6e;
}
.checkout-form__single-field :-ms-input-placeholder {
  /* Firefox */
  opacity: 1;
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6e6e;
}
.checkout-form__single-field ::-ms-input-placeholder {
  /* Firefox */
  opacity: 1;
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6e6e;
}
.checkout-form__single-field ::placeholder {
  /* Firefox */
  opacity: 1;
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6e6e;
}
.checkout-form__single-field :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6e6e6e;
}
.checkout-form__single-field ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6e6e6e;
}
.checkout-form__button {
  font-size: 16px;
  line-height: 1;
  width: 100%;
  padding: 15px 0;
  color: #ffffff;
  border: none;
  background: none;
  background-color: #fd614b;
}

.your-order-area h3 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 20px;
  color: #000000;
}
.your-order-area .your-order-wrap {
  padding: 30px 15px;
  background: #f1f1f1;
}
.your-order-area .your-order-wrap .your-order-product-info .your-order-top ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.your-order-area
  .your-order-wrap
  .your-order-product-info
  .your-order-top
  ul
  li {
  font-size: 16px;
  font-weight: 500;
  list-style: outside none none;
  color: #000000;
}
.your-order-area .your-order-wrap .your-order-product-info .your-order-middle {
  margin: 29px 0;
  padding: 19px 0 18px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
.your-order-area
  .your-order-wrap
  .your-order-product-info
  .your-order-middle
  ul
  li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 10px;
}
.your-order-area
  .your-order-wrap
  .your-order-product-info
  .your-order-bottom
  ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.your-order-area
  .your-order-wrap
  .your-order-product-info
  .your-order-bottom
  ul
  li {
  font-size: 14px;
  font-weight: 400;
  list-style: none;
}
.your-order-area
  .your-order-wrap
  .your-order-product-info
  .your-order-bottom
  ul
  li.your-order-shipping {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.your-order-area .your-order-wrap .your-order-product-info .your-order-total {
  margin: 18px 0 33px;
  padding: 17px 0 19px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
.your-order-area
  .your-order-wrap
  .your-order-product-info
  .your-order-total
  ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.your-order-area
  .your-order-wrap
  .your-order-product-info
  .your-order-total
  ul
  li.order-total {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}
.your-order-area
  .your-order-wrap
  .your-order-product-info
  .your-order-total
  ul
  li {
  font-size: 16px;
  font-weight: 500;
  list-style: outside none none;
  color: #fd614b;
}
.your-order-area .place-order > a {
  font-weight: 500;
  line-height: 1;
  z-index: 9;
  display: block;
  padding: 18px 20px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 50px;
  background-color: #fd614b;
}
.your-order-area .place-order > a:hover {
  background-color: #333;
}

/*=====  End of checkout  ======*/
/*=============================================
=            banner            =
=============================================*/
.banner-content {
  position: relative;
}
.banner-content__image a {
  display: block;
}
.banner-content__image img {
  width: 100%;
}
.banner-content__text {
  position: absolute;
  top: 50%;
  right: 60px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.banner-content__text .title {
  font-family: "Merriweather";
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 5px;
  color: #fd614b;
}
.banner-content__text .subtitle {
  font-family: "Consolas";
  font-size: 13px;
  color: #fd614b;
}

/*=====  End of banner  ======*/
/*=============================================
=            category            =
=============================================*/
.category-wrapper .col-12 .single-category {
  margin-bottom: 20px;
}
.category-wrapper .col-12:last-child .single-category {
  margin-bottom: 0;
}

.single-category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 30px;
  border-radius: 5px;
  background-color: #ffffff;
  background-position: center right;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
}
.single-category__image {
  -ms-flex-preferred-size: 35px;
  flex-basis: 35px;
}
.single-category__title {
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}
.single-category:hover .single-category__title {
  text-decoration: underline;
}

/*=====  End of category  ======*/

.list-container {
  max-height: 540px;
  overflow-y: scroll;
}

.list-container img {
  max-height: 70px;
}

.inputs input {
  width: 40px;
  height: 40px;
}

.bg-img .container {
  content: "";
  background: rgb(255 255 255 / 0.24);
  flex: 1 1;
  align-content: center;
}

.wp-right {
   right: 2%;
 }
 
 .wp-bottom {
   bottom: 15%;
 }